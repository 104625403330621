import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import {register} from './AccountApi'
import { useNavigate } from 'react-router';

export const Register = () => {
  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    mobile: Yup.string().max(10,'mobile number at least 10 digit').required('Mobile number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  });

  // Initial form values
  const initialValues = {
    name: '',
    mobile: '',
    email: '',
    password: '',
  };

const navigate = useNavigate()

  const mutation = useMutation(register,{

    onSuccess:(data)=>{
        console.log("hi",data)
    },
    onError:(error)=>{
        console.error('Error registering user:', error.response ? error.response.status : error.message);
        if(error.response ? error.response.status : error.message){
           alert("already exist")
        }
        
    }
  })
  // Form submission handler
  const handleSubmit = (values) => {
    // Handle registration logic (e.g., send data to the server)
    const userData = {
        name: values.name,
        mobile: values.mobile,
        email: values.email,
        password:values.password,
        tc:true
    }
    mutation.mutate(userData)

    console.log('Form values:', values);
  };

  return (
    <div className="py-16">
      <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
        <div
          className="hidden lg:block lg:w-1/2 bg-cover"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1546514714-df0ccc50d7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=667&q=80')`,
          }}
        ></div>
        <div className="w-full p-8 lg:w-1/2">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="mt-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                  <Field
                    name="name"
                    type="text"
                    className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                  />
                  <ErrorMessage name="name" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Mobile Number</label>
                  <Field
                    name="mobile"
                    type='number'
                    className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                  />
                  <ErrorMessage name="mobile" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Email Address</label>
                  <Field
                    name="email"
                    type="email"
                    className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="mt-4">
                  <div className="flex justify-between">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
                    <a href="/" className="text-xs text-gray-500">
                      Forget Password?
                    </a>
                  </div>
                  <Field
                    name="password"
                    type="password"
                    className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                  />
                  <ErrorMessage name="password" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="mt-8">
                  <button
                    type="submit"
                    className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600"
                  >
                    Sign Up
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="mt-4 flex items-center justify-between">
            <span className="border-b w-1/5 md:w-1/4"></span>
            <a href="/login" className="text-xs text-gray-500 uppercase">
              or Login
            </a>
            <span className="border-b w-1/5 md:w-1/4"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
