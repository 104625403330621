import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL;

export const TestListCategory = async()=>{
    try {
        // console.log("hi")
        const response = await axios.get(`${baseUrl}/test-category/`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}


export const TestListSubCategory = async(testName)=>{
    try {
        // console.log(testName,"hi")
        const response = await axios.get(`${baseUrl}/test-category/subcategory/${testName}/`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}


export const TestPaperList1 = async(testName)=>{
    try {
        // console.log(testName,"hi")
        const response = await axios.get(`${baseUrl}/test-category/topic-list/${testName}/`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}


export const TestQuestionsList = async(id)=>{
    try {
        // console.log(id,"hi")
        const response = await axios.get(`${baseUrl}/test-category/test-start/${id}/`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}