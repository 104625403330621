import React, { useEffect} from 'react'
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { TestListSubCategory} from '../api/Api';
import AdSense from '../../components/ads/AdSense';

export const TestListSubAdmin = () => {
    const { id } = useParams();
    // console.log(id, "testPapaer");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);
    // const [toggle, setToggle] = useState(false)

    const { data, isLoading, error } = useQuery(
        ['testpaper', id],
        () => TestListSubCategory(id),
        {
            enabled: !!id, // Ensures API call is made only when id is available
        }
    );
    console.log(data, "data")
    // const navigate = useNavigate()
    // const handleClick = (item)=>{
    //     navigate(`/test-series/test-paper-admin/${item.id}/`)
    // }
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading test papers: {error.message}</div>;

    return (
        <div className='flex flex-col lg:flex-row'>

            {/* Test Papers List */}
            <div className="flex-grow min-h-screen bg-gray-100 p-4">
                <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6 mx-auto">
                    {/* <h1 className='lg:hidden block' onClick={() => setToggle(!toggle)}>  »»Test List</h1> */}

                    <p className="breadcrumb text-gray-600 mb-4">
                        {/* Home » <span className="breadcrumb-link text-blue-600">location?.state?.class_name</span> » */}
                    </p>
                    {/* <h2 className="react-question text-4xl font-semibold mb-4">data?.chapter_name</h2> */}
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-4'>
                        {data?.map((item) => (
                            <div
                                key={item.id}
                                className="bg-custom-gray rounded-lg shadow-custom-shadow transform transition-all duration-300 group w-full"
                            >
                                {/* Image Section */}
                                {/* <div
                                    className="h-[120px] flex justify-around items-center w-full"
                                    style={{ background: 'linear-gradient(to top, #ddd6f3, #faaca8)' }}
                                >
                                    <img
                                        src={Reactjs}
                                        alt={item.name}
                                        className="w-[50px] h-[50px] mt-4 rounded-lg sm:w-[60px] sm:h-[60px] lg:w-[70px] lg:h-[70px]"
                                    />
                                    <img
                                        src={Reactjs}
                                        alt={item.name}
                                        className="w-[50px] h-[50px] mt-4 rounded-lg sm:w-[60px] sm:h-[60px] lg:w-[70px] lg:h-[70px]"
                                    />
                                </div> */}

                                {/* Content Section */}
                                <div className="m-4 text-left">
                                    <p className="text-lg font-bold text-gray-600 transition-colors duration-300 group-hover:text-gray-800">
                                        {item.sub_category}
                                    </p>
                                    <p className="text-sm text-gray-600">{item.syllabus_name}</p>
                                    <p className="text-sm text-gray-600">{item.description}</p>
                                    <p className="text-sm text-gray-600">{item.number_of_question}</p>
                                    <a href={`/test-series/test-paper-admin/${item.id}/`}>
                                        <button className="mt-4 w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-300">
                                            Start Test {item?.exam_sub_category}
                                        </button>
                                    </a>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            <AdSense />
        </div>
    );
};
