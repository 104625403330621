import axios from 'axios'


const baseUrl = process.env.REACT_APP_BASE_URL;


export const register = async (values) => {
    try {
        // Log the values you are passing
        console.log(values);
        
        // Make sure tc (terms and conditions) is set to true (assuming the user agrees)
        const requestData = {
            ...values,
            tc: true  // Ensure tc is always included and set to true
        };
        
        // Send the POST request
        const response = await axios.post(`${baseUrl}/api/user/register/`, requestData);
        
        // Log the response for debugging
        console.log(response.data, "status");
        
        // Return the response data
        return response.data;
    } catch (error) {
        // Log the error response
        alert(error.response ? error.response.status : error.message,"error")
        console.error(error.response ? error.response.data : error.message);
        throw error;
    }
};