import React from 'react'
import { Link } from 'react-router-dom';
import {Java,Spring,Hibernate,ApacheStruts,JUnit,ApacheMaven,SparkFramework,programming } from '../Image'
import AdSense from '../../ads/AdSense';

export const JavaTechnology = () => {
    const data = [
      {name: "coding questions",img: programming,href: "/coding/codingquestions",},
     {name: "Java",img: Java,href: "/java/java",},
    {name: "Spring",img: Spring,href: "/java/spring",},
    {name: "Hibernate",img: Hibernate, href: "/java/hibernate",},
    { name: "Apache Struts", img: ApacheStruts,  href: "/java/apachestruts",},
    {name: "JUnit",img: JUnit,href: "/java/junit",},
    {name: "Apache Maven",img: ApacheMaven,href: "/java/apachemaven",},
    {name: "Spark Framework",img: SparkFramework, href: "/java/sparkframework",},
    // { name: "C", img: react,  href: "/c",},
    // {name: "C++",img: react,href: "/c++",}
     ];
  return (
    <div className=" lg:w-custom-widthhome w-custom-width m-auto px-4 ">
    <p className="text-4xl font-bold mt-12 text-center font-custom-fontnew">Java Technology</p>

    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 justify-items-center mt-12">
        {data?.map((course, index) => (
            <div key={index} className="bg-custom-gray p-6 rounded-lg text-center shadow-custom-shadow transform transition-all duration-300 group w-full">
                <Link to={course?.href}>
                    <img
                        className="mx-auto w-3/4 transition-transform duration-300 transform group-hover:scale-110 rounded-lg"
                        src={course?.img}
                        alt={course?.name}
                    />
                    <p className="mt-4 text-lg font-custom-fontnew font-bold text-gray-800 transition-colors duration-300 group-hover:text-indigo-600">
                        {course?.name}
                    </p>
                </Link>
            </div>
        ))}
    </div>
    <div>
        <AdSense/>
    </div>
</div>
  )
}
